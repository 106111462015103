<template>
  <v-card
    class="data-table"
    :class="canBeSelected ? 'data-table--selectable' : ''"
    elevation="4"
  >
    <v-data-table
      calculate-widths
      :headers="headers"
      :items="!itemsLoading ? items : []"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="searchTerm"
      :sort-by="sortBy.value"
      :sort-desc="sortDesc"
      hide-default-footer
      hide-default-header
      class="elevation-0 custom-data-table"
      :server-items-length="itemsTotal"
      :class="itemsLoading ? 'v-data-table--loading' : ''"
    >
      <template v-if="itemsLoading" v-slot:body>
        <template v-if="$vuetify.breakpoint.lgAndDown">
          <backups-table-item-skeleton-mobile v-for="item in 5" :key="item" />
        </template>
        <template v-else>
          <backups-table-sort-skeleton />
          <backups-table-item-skeleton />
        </template>
      </template>

      <template v-slot:header="{ isMobile, props, on }" v-if="!noData">
        <table-custom-sort
          v-if="!$vuetify.breakpoint.lgAndDown && !itemsLoading"
          :isMobile="isMobile"
          :props="props"
          :v-on="on"
          :canBeSelected="canBeSelected"
          :allSelected="allSelected"
          @selectAll="handleSelectAll"
          v-on="$listeners"
        />
      </template>

      <template
        v-if="!$vuetify.breakpoint.lgAndDown"
        v-slot:item.select="{ item }"
      >
        <div>
          <checkbox
            @change="handleSelectItem(item)"
            :checked="checkedItems.includes(item.id)"
          />
        </div>
      </template>

      <template
        v-if="!$vuetify.breakpoint.lgAndDown"
        v-slot:item.name="{ item }"
      >
        <span class="bold">{{ item.name }}</span>
      </template>

      <template
        v-if="!$vuetify.breakpoint.lgAndDown"
        v-slot:item.date="{ item }"
      >
        <span v-html="formatDate(item.date)" />
      </template>

      <template
        v-if="!$vuetify.breakpoint.lgAndDown"
        v-slot:item.type="{ item }"
      >
        <status-label
          :small="true"
          :value="item.type"
          :status="typeColor(item.type)"
        />
      </template>

      <template
        v-if="!$vuetify.breakpoint.lgAndDown"
        v-slot:item.note="{ item }"
      >
        <div class="item-notes">
          <span class="p-4 font-weight-light">{{ item.note }}</span>
        </div>
      </template>

      <template
        v-if="!$vuetify.breakpoint.lgAndDown"
        v-slot:item.actions="{ item }"
      >
        <div class="d-flex actions-row">
          <template v-if="!hasBlockingActions(item)">
            <v-tooltip
              transition="custom-tooltip"
              open-delay="150"
              bottom
              z-index="99"
              offset-overflow
              nudge-bottom="4px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  small
                  @click="$emit('action-button-download', item)"
                  ><v-icon>$update</v-icon></v-btn
                >
              </template>

              <span>{{ $t('button.backup.download') }}</span>
            </v-tooltip>
            <v-tooltip
              transition="custom-tooltip"
              open-delay="150"
              bottom
              z-index="99"
              offset-overflow
              nudge-bottom="4px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  small
                  @click="$emit('action-button-restore', item)"
                  ><v-icon>$restore</v-icon></v-btn
                >
              </template>

              <span>{{ $t("button.restoreBackup") }}</span>
            </v-tooltip>
            <v-tooltip
              transition="custom-tooltip"
              open-delay="150"
              bottom
              z-index="99"
              offset-overflow
              nudge-bottom="4px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  small
                  @click="$emit('action-button-delete', item)"
                  ><v-icon>$thrash</v-icon></v-btn
                >
              </template>

              <span>{{ $t("button.deleteBackup") }}</span>
            </v-tooltip>
          </template>
          <template v-else>
            <div class="d-flex align-center">
              <loader
                v-if="hasActionInProgress(item)"
                :noMargins="true"
                :size="16"
                color="primary"
                class="mr-2"
              />
              <v-icon
                v-else-if="hasFailedAction(item)"
                :color="'error'"
                size="20"
                class="mr-1"
                >$alerterror</v-icon
              >
              <b style="white-space: nowrap">
                {{ getActionMessage(item) }}
                <span
                  v-if="hasFailedAction(item)"
                  @click="$emit('action-button-repeat', item)"
                  class="try-again"
                >
                  {{ $t('button.try') }}
                </span>
              </b>
            </div>
          </template>
        </div>
      </template>

      <!-- BACKUPS TABLE RESPONSIVE VIEW -->

      <template
        v-if="$vuetify.breakpoint.lgAndDown"
        v-slot:item="{ item, headers }"
      >
        <backups-table-responsive-item
          :item="item"
          :headers="headers"
          @update:checked="handleSelectItem(item)"
          :checked="checkedItems.includes(item.id)"
          v-on="$listeners"
        />
      </template>

      <template v-slot:no-data>
        <data-iterator-no-results-container
          @clearAllFilters="clearFilters"
          :searchTerm="searchTerm"
          v-if="searchTerm"
        />

        <div
          v-else
          class="d-flex flex-column align-center justify-center mx-auto my-10"
          style="max-width: 560px"
        >
          <svg
            width="98"
            height="64"
            viewBox="0 0 98 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M84 28C80.5 28 78 30 78 30C78 30 80 28 80 24C80 6 68 0 52 0C40 0 28 14 28 20C24 17.8898 21.9853 18 18 18C8.06189 18 0 26.0479 0 36C0 45.9521 6.03186 54 16 54H86C93.1458 54 98 48.186 98 41C98 33.814 94 28 84 28Z"
              fill="#F9F9FB"
            />
            <path
              d="M77.5701 8.2848C77.0495 8.2848 76.5595 8.40471 76.1307 8.61456C76.1307 8.40471 76.1614 8.22484 76.1614 8.01499C76.1614 4.14775 72.9458 1 68.9953 1C65.6879 1 62.9011 3.21842 62.0742 6.21627C61.4005 5.85653 60.6042 5.64668 59.7774 5.64668C57.1437 5.64668 55 7.74518 55 10.3233C55 12.9015 57.1437 15 59.7774 15H77.5701C79.4688 15 81 13.5011 81 11.6424C81 9.78373 79.4688 8.2848 77.5701 8.2848Z"
              fill="#F9F9FB"
              stroke="#B0B0D3"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <path
              d="M62 63H24C20 63 19 62 19 58V24C19 20 20 19 24 19H30C32.2845 19 33.728 19.0671 35.3389 20.7439L38 23C40 24.5 39.5 25 42 25H62C66 25 67 26 67 30V58C67 62 66 63 62 63Z"
              fill="white"
              stroke="#B0B0D3"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <path
              d="M43 51V35"
              stroke="#797997"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M37 41.0117L43 35.0313L49 41.0117"
              stroke="#797997"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M89 25H97"
              stroke="#797997"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
            />
            <path
              d="M93 29V21"
              stroke="#797997"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
            />
            <path
              d="M82 45C83.6569 45 85 43.6569 85 42C85 40.3431 83.6569 39 82 39C80.3431 39 79 40.3431 79 42C79 43.6569 80.3431 45 82 45Z"
              stroke="#B0B0D3"
              stroke-width="2"
              stroke-miterlimit="10"
            />
          </svg>

          <h4 class="mt-6">{{ $t('message.emptyTable.backups.title') }}</h4>
          <p class="p-1 pt-4 text-center text--body font-weight-light">
            {{ $t('message.emptyTable.backups.description')  }}
          </p>
          <v-btn
            rounded
            elevation="0"
            class="ml-0 mt-2"
            x-large
            color="primary"
            @click="$emit('add-new')"
          >
            <v-icon size="24px">$plus</v-icon>
            {{ $t('button.createBackup') }}
          </v-btn>
        </div>
      </template>

      <template v-slot:footer v-if="!noData">
        <div>
          <data-iterator-footer
            :page="page"
            :numberOfPages="numberOfPages"
            :possibleItemsPerPage="possibleItemsPerPage"
            :listStyle="listStyle"
            :itemsPerPage="itemsPerPage"
            v-on="$listeners"
          ></data-iterator-footer>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import DataIteratorMixin from "../../mixins/DataIteratorMixin";
import CustomTableMixin from "../../mixins/CustomTableMixin";

import DataIteratorFooter from "../dataIterator/DataIteratorFooter.vue";
import StatusLabel from "../StatusLabel.vue";
import TableCustomSort from "../dataIterator/TableCustomSort.vue";
import Checkbox from "../buttons/Checkbox.vue";
import DataIteratorNoResultsContainer from "../dataIterator/DataIteratorNoResultsContainer.vue";
import BackupsTableResponsiveItem from "./BackupsTableResponsiveItem.vue";

import BackupsTableItemSkeleton from "./BackupsTableItemSkeleton.vue";
import BackupsTableSortSkeleton from "./BackupsTableSortSkeleton.vue";
import BackupsTableItemSkeletonMobile from "./BackupsTableItemSkeletonMobile.vue";
import Loader from "../Loader.vue";

export default {
  components: {
    DataIteratorFooter,
    StatusLabel,
    TableCustomSort,
    Checkbox,
    DataIteratorNoResultsContainer,
    BackupsTableResponsiveItem,
    BackupsTableItemSkeleton,
    BackupsTableSortSkeleton,
    BackupsTableItemSkeletonMobile,
    Loader,
  },
  props: {
    headers: Array,
    itemsTotal: Number,
    itemsLoading: Boolean,
  },
  mixins: [DataIteratorMixin, CustomTableMixin],
  methods: {
    clearFilters: function () {
      this.searchTerm = "";
    },
    getActionMessage: function (item) {
      if (this.hasActionInProgress(item)) {
        if (item.async_status.create == "pending") {
          return this.$t('message.status.backup.create.pending');
        }
        if (item.async_status.restore == "pending") {
          return this.$t('message.status.backup.restore.pending');
        }
        if (item.async_status.delete == "pending") {
          return this.$t('message.status.backup.delete.pending');
        }
      }

      if (this.hasFailedAction(item)) {
        if (item.async_status.create == "failed") {
          return this.$t('message.status.backup.create.failed');
        }
        if (item.async_status.restore == "failed") {
          return this.$t('message.status.backup.restore.failed');
        }
        if (item.async_status.delete == "failed") {
          return this.$t('message.status.backup.delete.failed');
        }
      }
      return item;
    },
    hasActionInProgress(item) {
      return (
        item.async_status.create == "pending" ||
        item.async_status.create == "in_progres" ||
        item.async_status.restore == "pending" ||
        item.async_status.restore == "in_progres" ||
        item.async_status.delete == "pending" ||
        item.async_status.delete == "in_progres"
      );
    },
    hasFailedAction(item) {
      return (
        item.async_status.create == "failed" ||
        item.async_status.restore == "failed" ||
        item.async_status.delete == "failed"
      );
    },
    hasBlockingActions: function (item) {
      return this.hasActionInProgress(item) || this.hasFailedAction(item);
    },
    typeColor: function (value) {
      if (value == "Automatic") {
        return "success";
      } else if (value == "Manual") {
        return "purple";
      }
    },
    formatDate: function (value) {
      if (!value) return "---";

      if (typeof value === "string") {
        value = parseInt(value);
      }

      var options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };

      const formatedDate = new Date(value).toLocaleDateString(
        undefined,
        options
      );

      let date = formatedDate.slice(0, formatedDate.indexOf(","));
      let time = formatedDate.slice(
        formatedDate.indexOf(",") + 1,
        formatedDate.length
      );
      date = `<span class="font-weight-bold">${date}</span>`;
      return date + time;
    },
  },
  computed: {
    noData: function () {
      if (!this.items.length && !this.searchTerm) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item-notes {
  max-width: 180px;
  width: 180px;
}

.v-data-table::v-deep {
  colgroup {
    col:nth-child(7) {
      width: 180px;
    }
  }
  &.v-data-table--mobile > .v-data-table__wrapper tbody {
    display: contents;
  }
  &.data-table--loading {
    colgroup {
      col {
        width: calc(100% / 6);
      }
    }
  }
  .v-btn {
    margin-left: 10px;
    @media (max-width: 1183px) {
      margin-left: 0px;
      margin-right: 10px;
    }
  }
  .v-data-table__empty-wrapper {
    td {
      max-width: 100% !important;
      width: 100% !important;
    }
  }
}

.try-again {
  cursor: pointer;
  color: map-get($error, base);
  transition: color 0.24s ease;
  &:hover {
    color: map-get($primary, base);
  }
}
</style>
